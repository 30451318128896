
@media only screen and (min-width:600px) /*and (max-width:1286px)*/ {
  /* html {
    zoom: 0.67;
  } */
}

/* @media only screen and (min-width:1287px) and (max-width:1800px) {
  html {
    zoom: 0.8;
  }
} */

/* * {
  outline: 1px solid red;
} */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.screen-size-limit-overlay{
  display: none;


  @media screen and (max-width: 1000px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;    
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    height: 100vh;
    width: 100%;
    z-index: 1000;
    background-color: whitesmoke;
    color:gray;
    text-align: center;
    padding: 100px 20px;
    text-transform: capitalize;
  }
}

.dismiss-button {
  padding: 8px;
  background-color: gray;
  color: white;
  margin-top: 20px;
  border-radius: 5px;
  min-width: 200px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.page-footer {
  color: #8F8F8F;
  margin-top: 60px;
  padding: 10px;
  z-index: 1;
  position: relative;
  text-align: center;
  font-family: Cardo;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  text-overflow: ellipsis;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
