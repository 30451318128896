
.pdf-container{
    width: 100%;
    height: 80vh;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  
  .logo{
    position: absolute;
    height: 100px;
    width: 100px;
    top: 100px;
    left: 100px;
  }
  
  .bg{
     z-Index: 0px;
          position: "absolute";
          height: 100%;
          width: 100%,
  }
  
  .header{
    display: flex;
    width: '100%';
    align-items: center;
    
    padding: 20px;
    justify-content: center;
  }

  
  .back{
    cursor: pointer;
    font-family: Cardo;
    opacity: 90%;
    font-size:x-large;
    color: #2C6AB1;
  }
  
  
  .container{
    margin-top: 30px;
    border:  solid transparent;
    border-radius: 20px;
    background-color: #F3F3F3;
    box-shadow: 0 0 0 30px white;
    
  }
  .body{
    background-color: #F3F3F3;
    margin: auto;
    background-image: url(../../Assets/images/bg.png);
    height: 100vh;
  }
  
  form{
    margin-top: 20px;
    text-align: center;
  }
  
  .btn{
    margin-top: 10px;
  }
  
  @media print{
    .body{
      display: none;
    }
  }