
.answerAnalysisContainer {
  font-family: "Cardo";
  padding: 40px;
  width: calc(95vw - 450px);
}

.answerAnalysisTitle {
  color : #2C6AB1;
  font-family: "Cardo";
  font-size: 20px;
  font-style: italic;
  font-weight: 400;
  line-height: 137.4%; /* 31.602px */
  margin-bottom: 30px;
  text-align: left;
  margin-left: 20px;
}

.analysis-buttons-matrix {
  /* display: flex; */
  /* flex-direction: row; */
  /* margin-left: 0; */
  width: 80%;
  /* white-space: nowrap; */
  overflow-x:scroll;
}

.analysis-buttons-matrix::-webkit-scrollbar {
  display: none;
}

.line-laptop-screen {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.line-mobile-screen {
  display: none;
}

.mobile-hr-line {
  display: none;
}

@media only screen and (min-width: 861px) and (max-width: 1086px) {
  .answerAnalysisContainer {
    width: 90vw;
  }  

  .answerAnalysisTitle {
    margin-top: 50px;
    margin-left: 0;
    text-align: center;
  }

  .line-mobile-screen {
    display: unset !important;
  }

}

@media only screen and (max-width: 860px) {
  
  .answerAnalysisContainer {
    width: 90vw;
    padding: 2vw;
  }
  
  .line-laptop-screen {
    display: none !important;
  }

  .line-mobile-screen {
    display: unset !important;
  }

  .answerAnalysisTitle {
    text-align: center;
    margin-top: 50px;
    margin-left: 0;
  }

  .mobile-hr-line {
    display: unset !important;
  }
}