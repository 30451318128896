
.BreakdownBodyContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  overflow: visible;
  background-image: url("../../../Assets/images/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: center;
  font-size: 13px;
  color: #f3f3f3;
  font-family: Cardo;
}

.BreakdownPageContainer {
  display: "flex";
  flex: 1px;
  background-color: #f3f3f3;
}




@media only screen and (max-width:499px) {
  
  .foot{
    width: 100%;
  }
}