@import url("https://fonts.googleapis.com/css2?family=Cardo:wght@700&display=swap");

body {
  margin: 0;
  line-height: normal;
}

.streak {
  white-space: nowrap;
  display: inline-block;
  color: #8f8f8f;
  opacity: 0.88;
  margin: auto;
  text-align: center;
  width: 100%;
}

.on-nfo-portal {
  margin: 0;
  font-size: 26px;
  line-height: 32px;
}
.create-account-on-container {
  position: relative;
  line-height: 137.4%;
}
.field {
  position: relative;
  margin-bottom: 15px;
  padding-left: 10px;
  border-radius: 10px;
  background-color: rgb(188 209 231 / 30%);
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  width: 248px;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
}

.field1 {
  position: relative;
  margin-bottom: 3px;
  border-radius: 10px;
  background-color: rgb(208 208 208 / 30%);
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  width: 248px;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
}

.your-grade-cannot {
  position: relative;
  font-size: 9.96px;
  bottom: 5px;
}
.button2 {
  position: relative;
  border-radius: 51.512px;
  background: #2c6ab1;
  color: white;
  font-size: 18px;
  font-family: Cardo;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  width: 197px;
  margin-top: 30px;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
  margin-bottom: 15px;
}

.close {
  border-radius: 31.429px;
  position: relative;
  font-size: 18px;
  font-family: Cardo;
  box-sizing: border-box;
  width: 197px;
  margin-top: 30px;
  height: 40px;

  flex-shrink: 0;
  margin-bottom: 15px;
  color: #2063af;
}

.error-message {
  color: rgb(255, 109, 109);
  font-size: 12px;
  font-family: Cardo;
  margin-bottom: 10px;
}

.form-container,
.form-wrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.form-container {
  height: 430px;
  justify-content: center;
  padding: 5px 8px;
  gap: 15px;
}
.form-wrapper {
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border: 2px solid rgba(0, 0, 0, 0.03);
  height: 325px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.create-account-wrap,
.form-frame {
  position: absolute;
  left: calc(50% - 146px);
  height: 459px;
}
.create-account-wrap {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.form-frame {
  top: 160px;
  width: 292px;
  font-size: 18px;
  color: #2c6ab1;
}
.logo,
.nfo-logo-icon {
  position: absolute;
  width: 156px;
  height: 70px;
}

.modal-purchase{
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.out-of{
color: #2C6AB1;
text-align: center;
font-family: Cardo;
font-size: 20px;
font-style: normal;
opacity: 0.8;
font-weight: 400;
line-height: 20px /* 27.48px */
}

.scored{
  color: #2C6AB1;
  text-align: center;
  font-family: Cardo;
  font-size: 66px;
  font-style: normal;
  font-weight: 700;
  line-height: 137.4%;
}

.purchase {
  border: none;
  width: 183px;
  height: 29.902px;
  flex-shrink: 0;
  border-radius: 25.765px;

  background: rgba(99, 101, 103, 0.15);
  color: #2c6ab1;
}

.score {
  border-radius: 15.38px;
  border: 1.5px solid rgba(0, 0, 0, 0.10);
  font-size: 26;
  color: #2c6ab1;
  background-color: rgba(44, 106, 177, 0.11);
  text-align: center;
  padding: 10px 25px 10px 25px;
  align-items: center;
  justify-content: center;
}

.time {
border-radius: 15.38px;
border: 1.5px solid rgba(0, 0, 0, 0.05);
background-color:rgba(235, 236, 237, 0.3);
text-align: center;
display: flex;
flex-direction: column;
gap: 20px;
padding: 30px 30px 24px 30px;
color: #5f8cc1;
font-family: Cardo;
font-size: 24.602px;
font-style: normal;
font-weight: 400;
line-height: 116.4%; /* 28.637px */
}

h3 {
  color: #2c6ab1;
}

.nfo-logo-icon {
  top: 0;
  left: calc(50% - 78px);
  object-fit: cover;
}
.logo {
  top: 43px;
  left: calc(50% - 78.5px);
}
.oldCompletePageContainerClass {
  display: flex;
  flex-direction: column;
  position: relative;
  /* width: 100vw; */
  height: 100vh;
  overflow: hidden;
  background-image: url("../../Assets/images/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: center;
  font-size: 13px;
  color: #f3f3f3;
  font-family: Cardo;
}

.completeBodyContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  width: auto;
  height: 100%;
  overflow: hidden;
  background-image: url("../../Assets/images/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: center;
  font-size: 13px;
  color: #f3f3f3;
  font-family: Cardo;
  margin: 0 2% 0 2%;
}

.completePageContainer {
  display: flex;
  flex-direction: column;
  background-color: #f3f3f3;
}

.head-complete-page {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.backToDashboard {
  justify-content: center;
  position: relative;
  margin-left: 75;
  top: 50;
}

.completeContainerAfterHead {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: center;
}

.mobile-backToDashboard {
  display: none;
}

.foot {
  
  position: relative;
  margin-top: 10px;
  bottom: 10px;
  right: 0px;
  left: 0px;
}

@media only screen and (min-width: 861px) and (max-width: 1086px) {
  .head-complete-page {
    display: flex;
    flex-direction: column;
    align-content: center;
  }
  
  .completeContainerAfterHead {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
  }

  .backToDashboard {
    display: none;
  }

  .mobile-backToDashboard {
    display: flex !important;
    position: relative;
    justify-content: center;
  }
}

@media only screen and (max-width: 860px) {
  
  .head-complete-page {
    display: flex;
    flex-direction: column;
    align-content: center;
  }
  
  .completeContainerAfterHead {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
  }

  .backToDashboard {
    display: none;
  }

  .mobile-backToDashboard {
    display: flex !important;
    position: relative;
    justify-content: center;
  }
}

@media only screen and (max-width: 499px) {
  .foot {
  
    position: absolute;
    bottom: 10px;
  }
}
