/* @font-face {
    font-family: Cardo;
    src: url(./Assets/fonts/Cardo-Regular.ttf);
  } */

:not(:root):fullscreen {
  overflow: scroll;
  user-select: none;
}

.logo-icon {
  width: 202px;
  margin-bottom: 24px;
  height: 85px;
}

.qcontentwrap {
  padding: 30px;
  max-width: 1100px;
  margin: auto;
  margin-top: 20px;
}

.my-component {
  background: #F3F3F3;
}

.fullscreen-enabled .my-component {
  background: #fff;
}


.container {
  display: flex;
  flex-direction: row;

  width: 100vh;
  left: -500px;
  background-color: white;
}

.nfo-content {
  color: #2c6ab1;
  opacity: 0.9;
  font-size: 18px;
  white-space: nowrap;
  text-align: center;
}

.content .nfo-content {
  font-size: 16px;
}

.qguidecard {
  background-color: white;
  border-radius: 22px;
  border: 2.582px solid rgba(0, 0, 0, 0.09);

  width: 571px;
  height: 100%;
  padding: 30px;
}

.question-msq {
  padding: 10px 0px;
  color: #2C6AB1;
  font-style: italic;
  opacity: 0.7;
}

.exam-rules {
  width: fit-content;
  margin-left: auto;
  padding-right: 5px;
  color: #2c6ab1;
  opacity: 0.8;
  text-underline-offset: 2px;
  cursor: pointer;
}
.rules-modal {
  width: 100%;
  padding: 10px;
  border-radius: 20px;
  background-color: #2c6ab1;
  color: #fff;
  text-align: center;
  cursor: pointer;
}
.q-type {
  width: fit-content;
  margin-top: 5px;
  margin-left: auto;
  display: flex;
  gap: 5px;
  align-items: center;
}
.i-icon {
  width: 18px;
  height: 18px;
  border: 1px solid gray;
  border-radius: 50%;
  color: gray;
  text-align: center;
  font-size: 0.8rem;
  cursor: pointer;
}

.mcq-info, .msq-info {
  padding: 5px 10px;
  border-radius: 20px;
  background-color: #e46e18;
  color: #fff;
  font-size: 0.8rem;
}

.mcq-info {
  background-color: #2c6ab1;
}

.option-checkbox {
  appearance: none;
  width: 15px;
  height: 15px;
  margin: auto 0px;
  border: 2px solid rgba(100, 126, 217, 1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  color: currentColor;
  cursor: pointer;
}

.option-checkbox::before {
  content: "";
  width: 90%;
  height: 90%;
  margin: auto;
  background-color: rgba(100, 126, 217, 0.7);
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  /* clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%); */
}

.option-checkbox:checked::before {
  transform: scale(1);
}

.qCompleted {
  color: rgba(44, 106, 177, 1);
  opacity: 0.7;
  color: #2c6ab1;
  font-family: Cardo;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 137.4%;
}

.mobile-qCompleted {
  display: none;
}

.mobile-next-btn {
  display: none;
}

.next-btn {
  display: flex;
  width: 290px;
  height: 54px;
  padding: 9.342px 16.348px;
  justify-content: center;
  align-items: center;
  gap: 11.677px;
  border: none;
  border-radius: 36.701px;
  background: #2c6ab1;
  flex-shrink: 0;
  color: white;
}

.img-icon {
  height: 150px;
  align-self: center;
  padding: 12px;
}

.question {
  color: #2c6ab1;
  font-family: Cardo;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 137.4%;
  /* 27.48px */
  opacity: 0.9;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

.qSerialNum {
  margin-right: 10px;
  font-style: italic;
  font-size: medium;
  color: #2C6AB1;
  font-weight: 400;
  opacity: 0.7;
}

.questionWithSerialNumber {
  display: flex;
  flex-direction: row;
  color: #2c6ab1;
  font-family: Cardo;
  font-size: 20px;
  font-style: normal;
  line-height: 137.4%;
  /* 27.48px */
  opacity: 0.9;
  word-wrap: "break-word";
  overflow-wrap: "break-word";
  margin-top: 10px;
}

.flag {
  color: rgba(44, 106, 177, 1);
  font-family: Cardo;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 137.4%;
  /* 27.48px */
  opacity: 0.9;
  word-wrap: break-word;
  overflow-wrap: break-word;
  margin-top: 20px;
  align-items: end;
  text-align: right;
}

.mobile-flag {
  display: none !important;
}

.next {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
  margin-top: 20px;
  width: auto;
}

.bgup {
  position: fixed;
  top: 0;
  left: 30%;
  z-index: -10;
}

.options {
  display: block;
  border-radius: 11px;
  border: 2.582px solid rgba(0, 0, 0, 0.09);
  background: #d9d9d9 0.1;
  color: #2c6ab1;
  font-family: Cardo;
  font-size: 19px;

  padding: 12px;
  max-height: fit-content;
  margin-top: 20px;
  max-width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  cursor: pointer;
}

.options img {
  margin-right: 10px;
}

.studentid-wrap {
  border: none;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  width: 250px;
  /* margin-left: 34px; */
}

.studentid {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  padding: 20px;
  width: 100%;
}

.studentDetails {
  width: 245px;
}

.mobile-studentid {
  display: none !important;
  flex-direction: column;
  gap: 16px;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  padding: 20px;
  width: 100%;
}

body {
  background-color: #f3f3f3;
}

.contentwrap1 {
  display: flex;
  gap: 96px;
  flex-direction: row;
}

.footer {
  white-space: nowrap;
  line-height: 137.4%;
  display: inline-block;
  color: #8f8f8f;
  opacity: 0.88;
  margin: auto;
  margin-top: 60px;
  padding: 12px;
  text-align: center;
  width: 100%;
}

.mobile-footer {
  display: none;
}

.studentName {
  font-size: 18px;
  color: #2c6ab1;
}

.studentGrade {
  font-size: 18px;
  color: #2c6ab1;
}

.sign-out {
  font-size: 16px;
  color: #2c6ab1;
}

.button.secondary {
  background: rgba(99, 101, 103, 0.15);
  color: black;
}

.sign-out {
  color: #e46e18;
}

.supportbutton {
  border: none;
  font-size: 16px;
  background-color: #f3f3f3;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.support-text {
  color: #2c6ab1;
}

.examdate {
  font-size: 16px;
  color: #2c6ab1;
  display: flex;

  flex-direction: column;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

.rightpanel {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.line {
  border-radius: 10px;
  background-color: rgba(217, 217, 217, 0.4);
  width: 100%;
  height: 1px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
}

.header {
  display: flex;
  flex-direction: row;
  width: 100%;
  visibility: visible !important;
  justify-content: space-between;
  color: #2c6ab1;
  font-family: Cardo;
  font-size: 25px;
  font-style: normal;
  padding: 0;
  font-weight: 700;
  line-height: 154.4%;
}

.arrow-btn {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  /* borderradius: 11px; */
  margin: 4.5px;
  border: 2.582px solid rgba(83, 142, 195, 0.5);
  background: rgba(139, 224, 230, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Style the arrow icon */
.arrow-icon {
  width: 20px;
  height: 20px;
  fill: #2c6ab1;
}

.modal-content {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 70%;
  /* Adjust the width as needed */
  max-width: 400px;
  /* Set a maximum width if desired */
  background-color: white;
  border-radius: 20px;
  padding: 20px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.remaining-time {
  border-radius: 11px;
  border: 2.582px solid rgba(0, 0, 0, 0.09);
  background: rgba(180, 219, 255, 0.1);
  width: 251px;
  height: 111px;
  opacity: 0.7;
  flex-shrink: 0;
  color: rgba(17, 67, 197, 1);

  font-family: Cardo;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.stop {
  display: flex;
  width: 251px;
  /* same as for the above component */
  height: 48px;
  padding: 7.51px 13.143px;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  background: #c53111;
  color: white;
  border: none;
  border-radius: 29.504px;
}

.mobile-remaining-time {
  display: none;

  font-family: Cardo;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  text-align: center;
}

.mobile-stop {
  display: none !important;
  /* width: 178px; */
  height: 40px;
  /* padding: 7.51px 13.143px; */
  justify-content: center;
  align-items: center;
  gap: 9.388px;
  flex-shrink: 0;
  background: #c53111;
  color: white;
  border: none;
  border-radius: 29.504px;
  text-align: center;
}

.cont {
  display: inline-flex;
  width: 154px;
  height: 40px;
  padding: 7.51px 13.143px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 29.504px;
  border: none;
  background: #2c6ab1;
  color: white;
}

.comp {
  display: inline-flex;
  width: 154px;
  height: 40px;
  padding: 7.51px 13.143px;
  justify-content: center;
  align-items: center;
  border: none;
  margin-left: 10px;
  border-radius: 29.504px;
  background: #c53111;
  color: white;
}

.arrow-icon {
  width: 20px;
  height: 20px;
  fill: #2c6ab1;
}

.row {
  flex-direction: column;
}

.qcontentwrap {
  flex-direction: column;
}

.buttons-matrix {
  width: 245px;
}

.mobile-buttons-matrix {
  display: none !important;
}


@media only screen and (max-width: 860px) {
  .contentwrap1 {
    flex-direction: column;
    width: 100%;
    gap: 10px;
  }

  .header {
    justify-content: center;
  }

  .studentid-wrap {
    flex-direction: column;
    gap: 5px;
  }

  .logo-icon {
    display: flex;
    height: 100px;
    margin-top: 16px;
  }

  .remaining-time {
    display: none;
  }

  .stop {
    display: none;
  }

  .examinfo {
    order: 2;
  }

  .qguidecard {
    order: 3;
    width: 100%;
    margin: 0;
    align-self: center;
  }

  .rightpanel {
    order: 4;
  }

  .studentid {
    order: 5;
    width: 100%;
  }

  .studentDetails {
    display: none !important;
  }

  .buttons-matrix {
    display: none !important;
  }

  .mobile-buttons-matrix {
    display: unset !important;
  }

  .mobile-buttons-matrix {
    /* display: unset !important; */
    display: flex;
    flex-direction: row;
    margin-left: 0;
    width: 80vw;
    white-space: nowrap;
    overflow-x: scroll;
  }

  .bgup {
    display: none;
  }

  .container {
    flex-direction: column;
  }

  .qcontentwrap {
    flex-direction: column;
    width: 100%;
    margin-top: -20px;
  }

  .date {
    display: none;

    color: rgba(17, 67, 197, 1);
  }

  .modal-content {
    width: 100%;
    flex-direction: column;
  }

  .studentid-wrap {
    width: 100%;
    align-self: center;
    /* margin-left: 8px; */
  }

  .mobile-remaining-time {
    display: unset !important;
  }

  .question {
    color: #2c6ab1;
    font-family: Cardo;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 137.4%;
    /* 27.48px */
    opacity: 0.9;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: pre-wrap;
  }

  .mobile-stop {
    display: unset !important;
    align-self: center;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    margin-top: 20px;
  }

  .mobile-studentid {
    display: flex !important;
    width: 100%;
    margin-top: 20px;
  }

  .qCompleted {
    display: none !important;
  }

  .mobile-qCompleted {
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
    color: rgba(44, 106, 177, 1);
    opacity: 0.7;
    color: #2c6ab1;
    font-family: Cardo;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 137.4%;
  }

  .flag {
    display: none !important;
  }

  .mobile-flag {
    display: flex !important;
    position: relative;
    justify-content: center;
    color: rgba(44, 106, 177, 1);
    font-family: Cardo;
    font-size: 16px;
    font-style: normal;
    line-height: 137.4%;
    /* 27.48px */
    opacity: 0.9;
    word-wrap: break-word;
    overflow-wrap: break-word;
    margin-top: 20px;
  }

  .mobile-next-btn {
    display: flex;
    width: 100%;
    height: 48px;
    padding: 9.5px 28px;
    justify-content: center;
    align-items: center;
    gap: 11.677px;
    border: none;
    border-radius: 36.701px;
    background: #2c6ab1;
    flex-shrink: 0;
    color: white;
  }

  .next-btn {
    display: none !important;
  }

  .portal {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .options {
    width: 100%;
  }

  h1 {
    border-radius: 11px;
    border: 2.582px solid rgba(0, 0, 0, 0.09);
    background: rgba(180, 219, 255, 0.1);
    height: 56px;
    color: #1143c5;
    opacity: 0.7;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    justify-content: center;
    align-self: center;
  }

  .footer {
    display: none;
  }

  .mobile-footer {
    display: block;
    white-space: nowrap;
    line-height: 137.4%;
    display: inline-block;
    color: #8f8f8f;
    opacity: 0.88;
    margin: auto;
    margin-top: 60px;
    padding: 12px;
    text-align: center;
    width: 100%;
  }

  .exam-rules {
    padding-right: 20px;
  }
}