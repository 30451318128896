
.mobile-summary-card {
    display: none !important;
}

.parentContainer {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 0;
    flex-direction: column;
    bottom: 0;
    left: 0;
    right: 0;
}

.mobileWhiteCard {
    background-color: white;
    border-radius: 25px;
    border: 2px solid rgba(0, 0, 0, 0.10);
    display: flex;
    flex: 1;
    gap: 20px;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;
    padding-top: 30px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 30px;
    margin-top: 40px;
    height: auto;
    width: 90vw;
    /* marginLeft: "5vw" */
}

.mobileTime {
    border-radius: 15.38px;
    border: 1.5px solid rgba(0, 0, 0, 0.05);
    background-color:rgba(235, 236, 237, 0.3);
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px 30px 24px 30px;
    color: #5f8cc1;
    font-family: Cardo;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 116.4%; /* 28.637px */
    }

@media only screen and (max-width: 430px) {
    .mobile-summary-card {
        display: unset !important;
        justify-content: center;
        justify-self: center;
    }
}