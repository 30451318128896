@import url("https://fonts.googleapis.com/css2?family=Cardo:wght@700&display=swap");

body {
  margin: 0;
  line-height: normal;
}

.streak {
  white-space: nowrap;
  line-height: 137.4%;
  display: inline-block;
  color: #8f8f8f;
  opacity: 0.88;
  margin: auto;
margin-top: 650px;
  text-align: center;
  width: 100%;
 
}


.on-nfo-portal {
  margin: 0;
  font-size: 26px;
  line-height: 32px;
}
.create-account-on-container {
  position: relative;
  line-height: 137.4%;
}
.field {
  position: relative;
  margin-bottom: 15px;
  padding-left: 10px;
  border-radius: 10px;
  background-color: rgb(188 209 231 / 30%);
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  width: 248px;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
}

.field1 {
  position: relative;
  margin-bottom: 3px;
  border-radius: 10px;
  background-color: rgb(208 208 208 / 30%);
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  width: 248px;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
}

.your-grade-cannot {
  position: relative;
  font-size: 9.96px;
  bottom: 5px;
}
.button2 {
  position: relative;
  border-radius: 51.512px;
background: #2C6AB1;
  color: white;
  font-size: 18px;
  font-family: Cardo;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  width: 197px;
  margin-top: 30px;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
  margin-bottom: 15px;
}

.close{
    border-radius: 31.429px;
position: relative;
  font-size: 18px;
  font-family: Cardo;
  box-sizing: border-box;
  width: 197px;
  margin-top: 30px;
  height: 40px;

  flex-shrink: 0;
  margin-bottom: 15px;
  color: #2063af;

}



.error-message {
  color: rgb(255, 109, 109);
  font-size: 12px;
  font-family: Cardo;
  margin-bottom: 10px;
}

.form-container,
.form-wrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.form-container {
  height: 430px;
  justify-content: center;
  padding: 5px 8px;
  gap: 15px;
}
.form-wrapper {
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border: 2px solid rgba(0, 0, 0, 0.03);
  height: 325px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.create-account-wrap,
.form-frame {
  position: absolute;
  left: calc(50% - 146px);
  height: 459px;
}
.create-account-wrap {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.form-frame {
  top: 160px;
  width: 292px;
  font-size: 18px;
  color: #2c6ab1;
}
.logo,
.nfo-logo-icon {
  position: absolute;
  width: 156px;
  height: 70px;
}
.nfo-logo-icon {
  top: 0;
  left: calc(50% - 78px);
  object-fit: cover;
}
.logo {
  top: 43px;
  left: calc(50% - 78.5px);
}
.create-account {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: visible;
  background-image: url("../../Assets/images/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: center;
  font-size: 13px;
  color: #f3f3f3;
  font-family: Cardo;
}

.preQuizInfo {
  position: relative;
  overflow: visible;
  background-image: url("../../Assets/images/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: center;
  font-size: 13px;
  color: #f3f3f3;
  font-family: Cardo;
}

.InfoContParent {
  display: "flex";
  flex: 1px;
  background-color: #f3f3f3;
}




@media only screen and (max-width:499px) {
  
  .foot{
    width: 100%;
  }
}